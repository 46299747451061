@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#__next {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

.custom-bg {
  background: #aff4fe url("/bg_gradient_smaller.png") no-repeat center / cover;
  overflow-y: scroll;
}

.mono {
  font-family: monospace;
}

.content {
  margin: 15px;
}

.red {
  color: red;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-quick {
  animation: spin 0.4s linear infinite;	
}

.fix-radius {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0)
}